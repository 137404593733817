/**
 // @file Provides a set of functions for managing the last state of the Padlet Picker create flow.
 *
 * The functions in the vue composable allow you to:
 * - Save the last Padlet Picker create state to safe storage
 * - Retrieve the last Padlet Picker create state from safe storage
 * - Clear the last Padlet Picker create state
 * - Resume the Padlet Picker create flow if applicable
 */

import { trackEvent } from '@@/bits/analytics'
import { ContentPickerSource } from '@@/bits/content_picker'
import { asciiSafeAndCircularSafeStringify } from '@@/bits/json_stringify'
import { safeSessionStorage } from '@@/bits/safe_storage'
import { useContentPickerStore } from '@@/pinia/content_picker'
import { PadletSelectionIntent, usePadletPickerStore } from '@@/pinia/padlet_picker'
import { usePostComposerModalStore } from '@@/pinia/post_composer_modal_store'
import { useSurfaceContentPickerStore } from '@@/pinia/surface_content_picker'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import type { Cid, Library } from '@@/types'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'

const SAFE_STORAGE_KEY = 'last_padlet_picker_create_state'
export interface PadletPickerCreateState {
  postCid: Cid
  padletSelectionIntent: PadletSelectionIntent
  library: Partial<Library>
}

export const usePadletPickerCreateState = (): {
  savePadletPickerLastCreateStateToSafeStorage: typeof savePadletPickerLastCreateStateToSafeStorage
  getPadletPickerLastCreateStateFromSafeStorage: typeof getPadletPickerLastCreateStateFromSafeStorage
  clearLastCreateState: typeof clearLastCreateState
  resumeIfApplicable: typeof resumeIfApplicable
} => {
  const postComposerModalStore = usePostComposerModalStore()
  const { isDockableModalListRegistered } = storeToRefs(postComposerModalStore)
  const surfaceDraftsStore = useSurfaceDraftsStore()
  const { hasFetchedInitialPostDrafts } = storeToRefs(surfaceDraftsStore)
  const surfacePostsStore = useSurfacePostsStore()
  const surfacePostActionStore = useSurfacePostActionStore()
  const surfaceContentPickerStore = useSurfaceContentPickerStore()
  const contentPickerStore = useContentPickerStore()
  const padletPickerStore = usePadletPickerStore()

  const savePadletPickerLastCreateStateToSafeStorage = (state: PadletPickerCreateState): void => {
    const stringValue = asciiSafeAndCircularSafeStringify(state)
    safeSessionStorage.setItem(SAFE_STORAGE_KEY, stringValue)
  }

  const getPadletPickerLastCreateStateFromSafeStorage = (): PadletPickerCreateState | null => {
    const stringValue = safeSessionStorage.getItem(SAFE_STORAGE_KEY)
    if (stringValue == null) return null
    return JSON.parse(stringValue)
  }

  const clearLastCreateState = (): void => {
    safeSessionStorage.removeItem(SAFE_STORAGE_KEY)
    padletPickerStore.clearLastCreateState()
  }

  /**
   * Opens the draft composer modal and handles the necessary setup based on the provided post CID.
   *
   * This function checks if the draft for the given post CID already exists.
   * If not, it will check if an existing post exists with the same CID.
   * If the post does not exist, it creates a new draft with the given post CID and expands the composer modal.
   * If the post already exists, it starts editing the existing post and expands the composer modal.
   *
   * @param postCid - The CID of the post for which to open the draft composer modal.
   */
  const openDraftComposerModal = (postCid: Cid): void => {
    const stopWatchingModalDockableModalList = watch(
      [isDockableModalListRegistered, hasFetchedInitialPostDrafts],
      ([isDockableModalListReady, isInitialPostDraftsFetched]) => {
        if (!isDockableModalListReady || !isInitialPostDraftsFetched) return
        if (surfaceDraftsStore.draftByCid[postCid] == null) {
          if (surfacePostsStore.postEntitiesByCid[postCid] == null) {
            surfaceDraftsStore.startNewDraft({ shouldStartEditing: true, attributes: { cid: postCid } })
          } else {
            surfacePostsStore.startEditingPost({ postCid })
          }
        } else {
          // TODO: Modify start editing draft to accept specific draft id.
          // There is an edge case that is not handled yet where it may be possible that
          // the last create state stored a draft CID, which is the same as another existing draft's CID
          // This happens when the previous draft the user was at is not published and a new draft has been created in the meantime with the same CID
          surfaceDraftsStore.startEditingDraft(postCid)
          postComposerModalStore.expandComposerModal(postCid)
        }
        stopWatchingModalDockableModalList()
      },
    )
  }

  /**
   * Resumes the last Padlet Picker create state if applicable.
   *
   * This function checks if there is a last create state stored in safe storage.
   * If so, it restores the state and opens the appropriate panel (copy post, transfer post, or draft composer modal) based on the stored intent.
   * It also sets the Padlet Picker to create mode and opens the Content Picker.
   */
  const resumeIfApplicable = (): void => {
    const lastCreateState = getPadletPickerLastCreateStateFromSafeStorage()
    if (lastCreateState == null) return

    trackEvent('Padlet Picker V2', 'Resume from last create state', null, null, {
      padletSelectionIntent: lastCreateState.padletSelectionIntent,
    })

    if (lastCreateState.padletSelectionIntent === PadletSelectionIntent.CopyPost) {
      surfacePostActionStore.postBeingCopiedCid = lastCreateState.postCid
      surfacePostActionStore.xCopyPostPanel = true
    } else if (lastCreateState.padletSelectionIntent === PadletSelectionIntent.TransferPost) {
      surfacePostActionStore.postBeingTransferredCid = lastCreateState.postCid
      surfacePostActionStore.xTransferPostPanel = true
    } else {
      // Open draft composer modal, which is behind the content picker
      openDraftComposerModal(lastCreateState.postCid)

      // Open content picker
      surfaceContentPickerStore.showPostContentPicker()
      contentPickerStore.showContentSourceMenu()
      contentPickerStore.activeContentSource = ContentPickerSource.PadletBrowser
    }

    // Set padlet picker to create mode immediately and pre-poluate it with last create state
    padletPickerStore.goIntoCreateMode(lastCreateState)
  }

  return {
    savePadletPickerLastCreateStateToSafeStorage,
    getPadletPickerLastCreateStateFromSafeStorage,
    clearLastCreateState,
    resumeIfApplicable,
  }
}
